<template>
  <div class="nos-domaines" id="nos-domaines">
    <h3 class="titleNosDomaines">{{ title }}</h3>
    <img class="bg-filets" src="/images/altela-img/nos-domaines/bg-filets.png" />
    <div class="fancy-feature-twelve md-pt-50" id="about">
      <div class="bg-wrapper">
        <div class="container">
          <div class="row justify-content-center pb-50 md-pt-20">
            <div
                v-for="item in domains"
                :key="item.id"
                class="col-lg-3 col-md-6 col-6"
            >
              <NuxtLink :to="item.link + item.id" class="block-style-seventeen d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img class="iconImg" :src="item.icon" alt="" />
                  </div>
                  <div class="static-text">
                    <h3>{{ item.title }}</h3>
                  </div>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import domaines from "@/public/static/nos-domaines.json"
const domains = domaines
defineProps({
  title: String
})
</script>
<style scoped>
.nos-domaines {
  position: relative;
  overflow: hidden;
  padding-top: 4rem
}

.bg-filets {
  position: absolute;
  top: -60%;
  opacity: 0.1;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.container {
  max-width: 1450px;
}

.titleNosDomaines {
  text-align: center;
  font-size: 39px;
  font-family: Panton;
  text-transform: uppercase;
  font-style: italic;
  color: #003853;
  margin-bottom: 2rem;
}

.block-style-seventeen {
  background: #fff;
  border: 2px solid #003853;
  height: 100%;
}

.static-text h3 {
  color: #003853;
  font-family: Panton;
  text-transform: uppercase;
  font-size: 20px;
  font-style: italic;
  text-align: center;
}

.block-style-seventeen:before {
  background: #DFF3FD !important;
}

.block-style-seventeen:hover .static-text {
  opacity: 1 !important;
}

.row {
  row-gap: 3rem;
}

.bg-wrapper {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #DFDFE4;
}

@media screen and (max-width: 810px) {
  .nos-domaines {
    padding-top: 0;
  }

  .fancy-feature-twelve {
    padding-top: 0!important;
  }

  .titleNosDomaines {
    font-size: 20px;
    margin-bottom: 0;
  }

  .static-text h3 {
    font-size: 15px;
  }

  .bg-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
